import React from "react"
import { Link } from "gatsby"

export default class Footer extends React.Component {
  render() {
    return (
      <div className="bg-white mx-auto mt-6 mb-2">
        <nav className="py-2 text-center text-gray-600 tracking-wider text-sm xl:text-base grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 justify-center">
          <div className="py-2">
            <Link
              to="/courses/"
              className="hover:text-new-blue hover:underline"
            >
              Courses
            </Link>
          </div>

          <div className="py-2">
            <Link to="/blog/" className="hover:text-new-blue hover:underline">
              Latest Blogs
            </Link>
          </div>

          <div className="py-2 -ml-1">
            <Link
              to="/shubham-gautam/"
              className="hover:text-new-blue hover:underline"
            >
              Shubham Blogs
            </Link>
          </div>

          <div className="py-2 -ml-1">
            <Link
              to="/ravish-kumar/"
              className="hover:text-new-blue hover:underline"
            >
              Ravish Blogs
            </Link>
          </div>
          <div className="py-2">
            <Link
              to="/popular-tags/"
              className="hover:text-new-blue hover:underline"
            >
             Popular Tags
            </Link>
          </div>
          <div className="py-2">
            <a
              href="https://www.enjoymathematics.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-new-blue hover:underline"
            >
              <p className="tracking-wider">EnjoyMathematics</p>
            </a>
          </div>

          <div className="py-2 -ml-1">
            <Link to="/about/" className="hover:text-new-blue hover:underline">
              About Us
            </Link>
          </div>

          <div className="py-2">
            <Link
              to="/contact/"
              className="hover:text-new-blue hover:underline"
            >
              Contact Us
            </Link>
          </div>
          <div className="py-2">
            <Link
              to="/terms-and-conditions/"
              className="hover:text-new-blue hover:underline"
            >
              Terms and Conditions
            </Link>
          </div>
          <div className="py-2">
            <Link
              to="/refund-and-cancellation-policy/"
              className="hover:text-new-blue hover:underline"
            >
              Refund Policy
            </Link>
          </div>
          <div className="py-2">
            <Link
              to="/privacy-policy/"
              className="hover:text-new-blue hover:underline"
            >
              Privacy Policy
            </Link>
          </div>

          <div className="py-2">
            <Link
              to="/cookie-policy/"
              className="hover:text-new-blue hover:underline"
            >
              Cookie Policy
            </Link>
          </div>
        </nav>

        <div className="mt-2 text-sm leading-6 text-center text-gray-600 xl:text-base pb-6 tracking-wider">
          <p className="">©2023 Code Algorithms Pvt. Ltd.</p>
          <p className="">All rights reserved.</p>
        </div>
      </div>
    )
  }
}
