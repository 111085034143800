import React from "react"
import { Link } from "gatsby"
import DsaCourseImg from "../img/data-structures-and-algorithms-course.svg"
import SystemDesignCourseImg from "../img/system-design-course.svg"
import MachineLearningCourseImg from "../img/machine-learing-course.svg"
import DataScienceCourseImg from "../img/data-science-course.svg"
import NewsletterStrip from "../components/newsletterStrip"
import OopsCourseImg from "../img/oops-course.svg"
import NavigationBar from "../components/Navbar"
import Footer2 from "../components/footer3"
import { Helmet } from "react-helmet"

export default function AboutUs() {
  return (
    <div className="">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title> EnjoyAlgorithms Courses</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Learn industry and interview focused courses on data structures and algorithms, machine learning, system design and oops."
        />
      </Helmet>
      <div className=" sm:sticky top-0 z-50">
        <NavigationBar />
      </div>
      <div className="bg-light-white pt-6 md:pt-8 xl:pt-10 mb-6">
        <div className="container px-4 md:px-6 2xl:px-8 mb-6 mx-auto">
          <h1 className="text-xl lg:text-2xl 2xl:text-3xl text-center tracking-wider text-gray-900 text-left leading-8">
            Free Guided Courses
          </h1>

          <div className="grid mt-8 lg:mt-12 mx-auto my-2 gap-8 sm;gap-6 md:gap-8 lg:gap-10 sm:grid-cols-2 xl:grid-cols-3">
            <Link
              to="/data-structures-and-algorithms-course/"
              className="shadow-lg px-2 bg-light-white text-center rounded"
            >
              <div className="flex justify-center">
                <img
                  className="pointer-events-none object-center w-80 h-44 sm:w-64 sm:h-36 md:w-80 md:h-44"
                  src={DsaCourseImg}
                  alt="Data structures and algorithms interview course"
                />
              </div>

              <div className="grid justify-center mt-2 mb-6">
                <div className="z-30 tracking-wider shadow text-center text-base 2xl:text-lg px-4 py-2 rounded border border-new-purple bg-white text-new-purple hover:bg-new-purple hover:text-white transition duration-150">
                  <Link to="/data-structures-and-algorithms-course/">
                    Coding Interview Course
                  </Link>
                </div>
              </div>
            </Link>

            <Link
              to="/machine-learning-courses/"
              className="shadow-lg px-2 bg-light-white text-center rounded"
            >
              <div className="flex justify-center">
                <img
                  className="pointer-events-none object-center w-80 sm:w-64 sm:h-36 md:w-80 md:h-44"
                  src={MachineLearningCourseImg}
                  alt="Machine learning course for interview"
                />
              </div>

              <div className="grid justify-center mt-2 mb-6">
                <div className="z-30 tracking-wider shadow text-center text-base 2xl:text-lg px-4 py-2 rounded border border-new-purple bg-white text-new-purple hover:bg-new-purple hover:text-white transition duration-150">
                  <Link to="/machine-learning-courses/">
                    Machine Learning Course
                  </Link>
                </div>
              </div>
            </Link>

            <Link
              to="/system-design-courses/"
              className="shadow-lg px-2 bg-light-white text-center rounded"
            >
              <div className="flex justify-center">
                <img
                  className="pointer-events-none object-center w-80 h-44 sm:w-64 sm:h-36 md:w-80 md:h-44"
                  src={SystemDesignCourseImg}
                  alt="System design course for interview"
                />
              </div>

              <div className="grid justify-center mt-2 mb-6">
                <div className="z-30 tracking-wider shadow text-center text-base 2xl:text-lg px-4 py-2 rounded border border-new-purple bg-white text-new-purple hover:bg-new-purple hover:text-white transition duration-150">
                  <Link to="/system-design-courses/">System Design Course</Link>
                </div>
              </div>
            </Link>

            <Link
              to="/oops-course/"
              className="shadow-lg px-2 bg-light-white text-center rounded"
            >
              <div className="flex justify-center">
                <img
                  className="pointer-events-none object-center w-80 h-44 sm:w-64 sm:h-36 md:w-80 md:h-44"
                  src={OopsCourseImg}
                  alt="OOPS design course for interview"
                />
              </div>

              <div className="grid justify-center mt-2 mb-6">
                <div className="z-30 tracking-wider shadow text-center text-base 2xl:text-lg px-4 py-2 rounded border border-new-purple bg-white text-new-purple hover:bg-new-purple hover:text-white transition duration-150">
                  <Link to="/oops-course/">OOPS Design Course</Link>
                </div>
              </div>
            </Link>
            <Link
              to="/data-science-course/"
              className="shadow-lg px-2 bg-light-white text-center rounded"
            >
              <div className="flex justify-center">
                <img
                  className="pointer-events-none object-center w-80 h-44 sm:w-64 sm:h-36 md:w-80 md:h-44"
                  src={DataScienceCourseImg}
                  alt="Data science course for interview"
                />
              </div>

              <div className="grid justify-center mt-2 mb-6">
                <div className="z-30 tracking-wider shadow text-center text-base 2xl:text-lg px-4 py-2 rounded border border-new-purple bg-white text-new-purple hover:bg-new-purple hover:text-white transition duration-150">
                  <Link to="/data-science-course/">Data Science Course</Link>
                </div>
              </div>
            </Link>
          </div>
          <div className="mt-6">
            <NewsletterStrip />
          </div>
          <Footer2 />
        </div>
      </div>
    </div>
  )
}
